﻿/* MEDIA QUERIES */
$media-query-phone: "screen and (max-width: 767px)";
$media-query-tablet: "screen and (max-width: 1023px)";
$media-query-desk-up: "screen and (min-width: 1024px)";
$media-query-desk: "screen and (max-width: 1280px)";
$media-query-desk-big: "screen and (max-width: 1512px)";
$media-query-max: "screen and (max-width:1920px)";



// BREAKPOINTS
$breakpoints: ( 
    "phone": $media-query-phone, 
    "tablet": $media-query-tablet, 
    "desk-up": $media-query-desk-up, 
    "desk": $media-query-desk, 
    "desk-big": $media-query-desk-big, 
    "max": $media-query-max );

// Fonts
$font-family-playfair: 'Playfair Display', serif; 
$font-family-lato: "Lato", sans-serif;

//Colors
$color-white: #FFFFFF;
$color-black: #000000;

$color-gray-fill-50: #FBFBF9;
$color-gray-fill-100: #F6F6F3;
$color-gray-fill-200: #EBEAE5;
$color-gray-fill-300: #DBDAD1;
$color-gray-fill-400: #B0AD9C;
$color-gray-fill-500: #807E6B;
$color-gray-fill-600: #63604B;
$color-gray-fill-700: #524F38;
$color-gray-fill-800: #37351F;
$color-gray-fill-900: #1C1B0C;

$color-primary-fill-50: #FFFCE6;
$color-primary-fill-100: #FCE95E;
$color-primary-fill-200: #FCE541;
$color-primary-fill-300: #FBE123;
$color-primary-fill-400: #FADD05;
$color-primary-fill-500: #DEC404;
$color-primary-fill-600: #BEA803;
$color-primary-fill-700: #A69611;
$color-primary-fill-800: #84770E;
$color-primary-fill-900: #6A600B;

$color-success-fill-50: #F5FBE9;
$color-success-fill-100: #A7DD39;
$color-success-fill-200: #9DD825;
$color-success-fill-300: #8FC422;
$color-success-fill-400: #82B21F;
$color-success-fill-500: #76A21C;
$color-success-fill-600: #6A9219;
$color-success-fill-700: #5F8316;
$color-success-fill-800: #557614;
$color-success-fill-900: #4C6A12;

$color-danger-fill-50: #FDEEEC;
$color-danger-fill-100: #F9C1BC;
$color-danger-fill-200: #F59087;
$color-danger-fill-300: #F16659;
$color-danger-fill-400: #EE4131;
$color-danger-fill-500: #E72413;
$color-danger-fill-600: #C41F10;
$color-danger-fill-700: #A71A0E;
$color-danger-fill-800: #8E160C;
$color-danger-fill-900: #79130A;

$color-warning-fill-50: #FFF8E6;
$color-warning-fill-100: #FFF3D6;
$color-warning-fill-200: #FFF0CB;
$color-warning-fill-300: #FFDE8F;
$color-warning-fill-400: #FFCE5B;
$color-warning-fill-500: #FFC02E;
$color-warning-fill-600: #FFB201;
$color-warning-fill-700: #DA9800;
$color-warning-fill-800: #B98100;
$color-warning-fill-900: #9D6E00;

$color-info-fill-50: #EBEDFF;
$color-info-fill-100: #8E9CFE;
$color-info-fill-200: #5B6FFD;
$color-info-fill-300: #2F48FC;
$color-info-fill-400: #0926FB;
$color-info-fill-500: #041EDE;
$color-info-fill-600: #0319BD;
$color-info-fill-700: #0315A1;
$color-info-fill-800: #031289;
$color-info-fill-900: #030F74;

$color-misc-blue: #141444;
$color-misc-gold: #D6B467; 
$color-misc-purple: #7B61FF;
$color-misc-stormy-azur: #5b7983;
$color-misc-rosy-pearl: #e5cbca;

$dropshadow-small: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
$dropshadow-base: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
$dropshadow-medium: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
$dropshadow-large: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
$dropshadow-huge: 0px 25px 50px rgba(0, 0, 0, 0.25);
$dropshadow-inner: inset 0px 2px 4px rgba(0, 0, 0, 0.06);

