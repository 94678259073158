﻿/// <reference path="../__references.scss" />

$component-name: 'Menu';
$component-base: '.#{$component-name}';
$component-transition: '.5s ease';

#{$component-base} {
    &-closeButton {
        min-width: 32px;
        min-height: 32px;
        padding: 0;

        & > i {
            margin-right: 0;
        }
    }

    &-main, &-links {
        display: flex;
        justify-content: flex-end;
    }

    &-main {
        margin: 10px 0 6px;

        @include at-breakpoint(desk) {
            display: none;
        }
    }

    &-links {
        margin-bottom: 4px;

        &Link {
            @include reset-link;
            margin-right: 24px;
            display: none;

            @include at-breakpoint(desk) {
                display: flex;
                align-items: center;
            }
        }

        @include at-breakpoint(desk) {
            margin-bottom: 0;
        }
    }

    &-quickMobile {
        margin-top: 16px;
        display: none;

        @include at-breakpoint(desk) {
            display: block;
        }
    }

    &-item {
        @include reset-link;
        cursor: pointer;
        display: flex;
        align-items: center;

        & > i {
            color: $color-primary-fill-500;
            @include font-size(22);
            margin-right: 8px;
            font-weight: normal;

            @include at-breakpoint(desk-big) {
                @include font-size(20);
                margin-right: 6px;
            }
        }

        &.-small {
            @include body-base-normal;
            padding: 0;
            margin-right: 32px;
            display: flex;
            align-items: center;

            & > i {
                margin-left: 4px;
                margin-bottom: 2px;
                color: $color-gray-fill-300;
                font-weight: normal;
            }

            &:hover {
                span {
                    text-decoration: underline;
                }

                & > i {
                    color: $color-gray-fill-400;
                }
            }
        }

        @include at-breakpoint(desk) {
            display: none !important;
        }

        &.-search {
            & > i {
                color: $color-gray-fill-900;
                @include font-size(22);

                @include at-breakpoint(desk-big) {
                    @include font-size(20);
                }
            }

            &:hover > i {
                color: $color-primary-fill-500;
            }
        }

        &:not(.-small):not(.-search) {
            @include body-huge-normal;
            font-weight: bold;
            padding: 4px 16px;
            border-bottom: 2px solid transparent;
            transition: border-color #{$component-transition}, color #{$component-transition};
            position: relative;

            @include at-breakpoint(desk-big) {
                @include body-large-normal;
                font-weight: bold;
                padding: 4px 10px;
            }

            &:hover {
                border-color: $color-gray-fill-300;
            }

            &.selected {
                border-color: $color-primary-fill-500;
                position: relative;
            }

            &.open {
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid $color-gray-fill-200;
                    position: absolute;
                    display: block;
                    bottom: -24px;
                    left: calc(50% - 8px);
                }
            }
        }
    }

    &-subMenu {
        display: flex;
        flex-wrap: wrap;
        margin-left: -32px;

        @include at-breakpoint(desk) {
            display: block;
            margin-left: 0;
        }
    }

    &-dropdown {
        position: fixed;
        display: none;
        opacity: 0;
        height: 0;
        top: 136px;
        left: 0;
        right: 0;
        z-index: 9998;
        background: transparent;
        border-top: 2px solid transparent;
        transition: all #{$component-transition};
        overflow: hidden;
        -webkit-box-shadow: 0 5px 4px -4px $color-gray-fill-400;
        -moz-box-shadow: 0 5px 4px -4px $color-gray-fill-400;
        box-shadow: 0 5px 4px -4px $color-gray-fill-400;

        @include at-breakpoint(desk) {
            opacity: 1;
            transform: translateX(100%);
            top: 0;
            height: 100vh !important;
            background: $color-white;
        }

        &.open {
            display: block;
            opacity: 1;
            background: $color-white;
            border-color: $color-gray-fill-200;

            @include at-breakpoint(desk) {
                transform: translateX(0);
            }
        }

        &Overlay {
            content: '';
            @include background-opacity($color-gray-fill-500, 0.5);
            position: fixed;
            top: 136px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            transition: all #{$component-transition};
            pointer-events: none;
            opacity: 0;
            transform: translateX(-100vw);
            transition: opacity .5s ease;

            @include at-breakpoint(desk) {
                display: 0;
            }

            &.open {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &Header {
            @include heading-xl;
            display: none;
            height: 88px;
            padding: 8px 16px;
            background: $color-gray-fill-200;

            @include at-breakpoint(desk) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &Side {
                width: 60px;

                &.-right {
                    text-align: right;
                }
            }

            &Logo {
                background-image: url('/images/Logo.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 76px;
                height: 72px;
            }
        }

        &Content {
            overflow-y: auto;
            overflow-x: hidden;

            @include at-breakpoint(desk) {
                height: calc(100vh - 88px);
                padding-bottom: 100px;
            }
        }

        &Part {
            opacity: 0;
            width: 100%;
            height: 0;
            padding: 40px 64px 64px;
            display: none;

            &-level3, &-level4 {
                display: none;
            }

            &.-level1.active {
                display: block;
                opacity: 1;
                height: auto;

                #{$component-base} {
                    &-dropdownItem.-level2 {
                        opacity: 1;
                    }
                }
            }

            @include at-breakpoint(desk) {
                &-level3, &-level4 {
                    display: unset;
                }

                opacity: 1;
                height: auto;
                padding: 0;
                display: none;

                &.open {
                    display: block;
                }

                & > .Wrapper-inner {
                    margin: 0;
                    margin-left: 16px;
                }

                &.-level1 {
                    border-bottom: 1px solid $color-gray-fill-300;

                    & > .Wrapper-inner > #{$component-base}-subMenu {
                        margin: 8px 0;
                    }
                }
            }
        }

        &Item {
            margin-left: 32px;
            width: calc(33% - 32px);
            opacity: 0;
            transition: all #{$component-transition};
            display: flex;
            align-items: center;
            height: auto;
            border-bottom: 1px solid $color-gray-fill-300;

            @include at-breakpoint(desk) {
                width: 100%;
                margin-left: 0;
                opacity: 1;
                border-bottom: 0;
            }

            & > i {
                color: $color-primary-fill-500;
                @include font-size(22);
                margin-right: 8px;
                font-weight: normal;

                @include at-breakpoint(desk-big) {
                    @include font-size(20);
                    margin-right: 6px;
                }
            }

            &Link {
                @include reset-link;
                @include body-base-normal;
                font-weight: bold;
                padding: 16px 12px;
                border-left: 4px solid transparent;
                flex: 1;

                & > i {
                    color: $color-primary-fill-500;
                    @include font-size(22);
                    margin-right: 8px;
                    font-weight: normal;
                }

                @include at-breakpoint(desk) {
                    flex: 1;
                }
            }

            &Toggle {
                display: none;
            }

            &:hover & {
                &Link {
                    border-color: $color-gray-fill-300;
                }
            }

            &.active & {
                &Link {
                    border-color: $color-primary-fill-500;
                }
            }

            &.-level1 {
                display: none;
            }

            @include at-breakpoint(desk) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                border-top: 0;

                &.-level1 {
                    display: flex;
                }

                &.-small & {
                    &Link {
                        height: 46px;

                        & > span {
                            flex: 1;
                            display: block;
                        }

                        & > i {
                            color: $color-gray-fill-400;
                            margin: 0;
                        }
                    }
                }

                &Link {
                    padding: 12px 12px;
                    font-weight: normal;
                    display: flex;
                    align-items: center;
                }

                &Toggle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 65px;
                    align-self: stretch;
                }

                &.-level1 {
                    border-bottom: 1px solid $color-gray-fill-300;
                }

                &.-level1 & {
                    &Link {
                        padding: 20px 12px;
                        font-weight: bold;
                    }

                    &Toggle {
                        border-left: 1px solid $color-gray-fill-300;
                    }
                }

                &.open & {
                    &Toggle {
                        border-left-color: transparent;

                        i {
                            transform: scaleY(-1)
                        }
                    }
                }
            }
        }
    }
}
