﻿/// <reference path="../__references.scss" />

$component-name: 'Article';
$component-base: '.#{$component-name}';

#{$component-base} {
    :not(.Breadcrumbs) + & {
        padding-top: 30px;
    }

    &-part {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 64px;

        @include at-breakpoint(phone) {
            display: block;
            padding-bottom: 32px;
        }

        &.-gray {
            background: $color-gray-fill-200;
        }

        &.-slim {
            padding-bottom: 0;
        }

        &.-grayToWhite {
            background: linear-gradient(180deg, $color-gray-fill-200 50%, $color-white 50%);
            margin-bottom: 64px;
            padding-bottom: 0;

            @include at-breakpoint(phone) {
                margin-bottom: 32px;
            }
        }

        &.-whiteToGray {
            background: linear-gradient(180deg, $color-white 50%, $color-gray-fill-200 50%);
            padding-bottom: 0;

            @include at-breakpoint(phone) {
                margin-bottom: 32px;
            }
        }
    }

    &-content {
        width: 100%;
        margin: 0 auto;
        max-width: 768px;

        &.-full {
            margin: 0;
            max-width: none;

            #{$component-base}-heading {
                max-width: 786px;
            }

            #{$component-base}-intro {
                max-width: 1010px;
            }
        }
    }

    &-heading {
        @include heading-6xl;
        margin-bottom: 16px;

        @include at-breakpoint(tablet) {
            @include heading-3xl;
            margin-bottom: 16px;
        }
    }

    &-intro {
        @include lead-giant;

        &.-thin {
            font-weight: normal;
        }

        @include at-breakpoint(tablet) {
            @include lead-base;

            &.-thin {
                font-weight: normal;
            }
        }

        & + & {
            margin-top: 16px;
        }

        a {
            @include defaultlinkstyle;
        }
    }

    &-category {
        @include body-base-loose;
        color: $color-gray-fill-500;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &-image {
        width: 100%;
        max-width: 1216px;
    }

    &-intro + &-body {
        margin-top: 16px;
    }

    &-body {
        & :first-child {
            margin-top: 0 !important;
        }

        & :last-child {
            margin-bottom: 0 !important;
        }

        a {
            @include defaultlinkstyle;
        }

        p, li {
            @include body-giant-normal;
            margin-bottom: 8px;

            @include at-breakpoint(tablet) {
                @include body-base-normal;
                margin-bottom: 8px;
            }
        }

        br {
            margin-bottom: 8px;
        }

        h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
            margin-top: 16px;
        }

        p + h2, p + h3, p + h4, p + h5, p + h6 {
            margin-top: 80px;

            @include at-breakpoint(tablet) {
                margin-top: 48px;
            }
        }
    }

    &-button {
        margin-top: 16px;
    }

    & img {
        height: auto;
    }
}
