﻿/// <reference path="../__references.scss" />

$component-name: 'Wrapper';
$component-base: '.#{$component-name}';

#{$component-base} {
    &-content {
        padding-bottom: 80px;
        margin: 0;

        &.-blocks {
            padding: 0;
        }
    }

    &-inner {
        max-width: 1792px;
        margin: 0 auto;
        width: 100%;

        @include at-breakpoint(max) {
            margin: 0 32px;
            width: calc(100% - 64px);
        }

        @include at-breakpoint(tablet) {
            max-width: none;
            margin: 0 16px;
            width: auto;
            min-width: calc(100% - 32px);

            &.-nomargin {
                margin: 0;
                width: 100%;
            }
        }

        &.-small {
            max-width: 1216px;
            margin: 0 auto;
            width: 100%;

            @include at-breakpoint(max) {
                margin: 0 auto;
                width: 100%;
            }

            @include at-breakpoint(desk) {
                margin: 0 32px;
                width: calc(100% - 64px);
            }

            @include at-breakpoint(tablet) {
                max-width: none;
                margin: 0 16px;
                width: calc(100% - 32px);

                &.-nomargin {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        &.-smaller {
            max-width: 876px;
            margin: 0 auto;
            width: 100%;

            @include at-breakpoint(max) {
                margin: 0 auto;
                width: 100%;
            }

            @include at-breakpoint(desk) {
                max-width: 768px;
                margin: 0 auto;
                min-width: unset;
                width: 100%;
            }

            @include at-breakpoint(phone) {
                max-width: none;
                margin: 0 16px;
                width: calc(100% - 32px);

                &.-nomargin {
                    margin: 0;
                    width: 100%;
                }
            }
        }

        &.-smallest {
            max-width: 768px;

            @include at-breakpoint(max) {
                margin: 0 auto;
            }

            @include at-breakpoint(phone) {
                max-width: none;
                margin: 0 16px;

                &.-nomargin {
                    margin: 0;
                }
            }
        }
    }



    &-page {
        max-width: 850px;
        margin: 0 auto;

        @include at-breakpoint(max) {
            margin-left: 120px;
            margin-right: 120px;
        }

        @include at-breakpoint(tablet) {
            margin-left: 20px;
            margin-right: 20px;
        }

        &.-flush {
            margin: 0 auto;
        }
    }

    &-site {
        padding-top: 136px;
        display: flex;
        flex-direction: column;
        height: 100vh;

        @include at-breakpoint(desk) {
            padding-top: 70px;
        }
    }
}
