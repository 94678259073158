﻿/// <reference path="../__references.scss" />

$component-name: 'Video';
$component-base: '.#{$component-name}';

#{$component-base} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        background: $color-gray-fill-100;
        margin-bottom: 16px;
    }

    &-placeholder {
        margin-bottom: 16px;

        &Link {
            display: block;
            position: relative;

            &.-youtube:before {
                content: '';
                width: 40px;
                height: 40px;
                position: absolute;
                background: white;
                top: calc(50% - 20px);
                left: calc(50% - 20px);
                bottom: auto;
            }

            & > i {
                z-index: 2;
                font-size: 50px;
                left: 15px;
                bottom: 0;
                position: absolute;
                height: 70px;
                width: 70px;
                display: flex;
                align-items: center;

                &:before {
                    color: $color-gray-fill-300;
                    transition: color 0.5s ease;
                }
            }

            &.-youtube {
                & > i {
                    font-size: 70px;
                    top: calc(50% - 35px);
                    left: calc(50% - 35px);

                    &:before {
                        color: #393431;
                    }
                }
            }

            &:hover {
                & > i:before {
                    color: $color-white;
                }
            }

            &.-youtube:hover {
                & > i:before {
                    color: #FF0000;
                }
            }
        }
    }
}
