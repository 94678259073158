﻿/// <reference path="../__references.scss" />

$component-name: 'Button';
$component-base: '.#{$component-name}';

#{$component-base} {
    @include reset-link;
    @include reset-button;
    @include body-small-loose;
    background: $color-primary-fill-500;
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    position: static;
    min-width: 100px;
    border-radius: 8px;
    transition: background .5s ease, border-color .5s ease;

    & > i {
        font-size: 18px;
        font-weight: normal;
        margin-right: 8px
    }

    & > span + i {
        margin-right: 0;
        margin-left: 8px;
    }

    &.-big {
        padding: 20px 40px;
    }

    &.-small {
        padding: 8px;
        min-width: auto;
    }

    &.-icononly {
        & > i {
            margin: 0 !important;
        }
    }

    &.-contour {
        border: 2px solid $color-primary-fill-500;
        background: transparent !important;

        &:hover {
            border-color: $color-primary-fill-600;
        }
    }

    &.-basic {
        background: $color-gray-fill-300;
        border-color: $color-gray-fill-300 !important;

        &:hover {
            background: $color-gray-fill-400;
            border-color: $color-gray-fill-400 !important;
        }
    }

    &.-dark {
        background: $color-gray-fill-900;
        border-color: $color-gray-fill-900 !important;
        color: $color-white;

        &:hover {
            background: $color-gray-fill-800;
            border-color: $color-gray-fill-800 !important;
        }
    }

    &:hover {
        background: $color-primary-fill-600;
    }

    &-hamburger {
        width: 15px;
        height: 14px;
        display: block;
        margin-left: 10.5px;
        position: relative;

        & > span {
            background: $color-gray-fill-900;
            transition: all .5s ease;
            display: block;
            position: absolute;
            right: 0;
            height: 2px;
            width: 100%;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                margin-top: -1px;
            }

            &:nth-child(3) {
                bottom: 0px;
            }
        }
    }

    &.-menu {
        display: none;
    }

    @include at-breakpoint(desk) {
        &.-menu {
            padding: 8px 16px;
            display: inline-flex;
            border-radius: 0 8px 8px 0;
        }

        &.-becomeMember {
            padding: 8px 16px;
            border-radius: 8px 0 0 8px;
        }
    }
}
