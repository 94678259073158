﻿/// <reference path="../__references.scss" />

$component-name: 'Expandable';
$component-base: '.#{$component-name}';

#{$component-base} {    
    &:not(.active) {
        max-height: 400px;
        overflow: hidden;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            height: 200px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%);
        }
    }

    &-buttonRow {
        position: absolute;
        bottom: 0;
        z-index: 5;
        width: 100%;
        text-align: center;
    }

    &.active & {
        &-buttonRow {
            margin-top: 16px;
            position: relative;
        }
    }
}
