﻿/// <reference path="../__references.scss" />

$component-name: 'Quote';
$component-base: '.#{$component-name}';

#{$component-base} {
    display: flex;
    align-items: center;

    @include at-breakpoint(tablet) {
        flex-direction: column;
    }

    @include at-breakpoint(phone) {
        padding: 20px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        flex: 1;
        text-align: center;
    }

    &-image {
        width: 100%;

        &Container {
            min-width: 184px;
            overflow: hidden;
            margin-right: 32px;

            @include at-breakpoint(tablet) {
                margin-right: 0;
                margin-bottom: 32px;
            }
        }
    }

    &-text {
        @include heading-3xl;
        margin-top: 16px;
        text-align: left;

        @include at-breakpoint(tablet) {
            @include heading-2xl;
            text-align: center;
            margin-top: 32px;
        }

        &:before {
            content: '“';
        }

        &:after {
            content: '”';
        }

        @include at-breakpoint(desk) {
            padding: 0 20px;
        }
    }
}
