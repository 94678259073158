﻿/// <reference path="../__references.scss" />

$component-name: 'List';
$component-base: '.#{$component-name}';

#{$component-base} {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;

    @include at-breakpoint(phone) {
        display: block;
        margin-left: 0;
    }

    &-heading {
        @include heading-5xl;
        margin-bottom: 40px;

        @include at-breakpoint(tablet) {
            @include heading-2xl;
            margin-bottom: 16px;
        }
    }

    &.-full {
        display: block;
        padding: 0;
        margin-left: 0;
    }

    & + & {
        margin-top: 80px;
    }

    &.-gray {
        background: $color-gray-fill-200;
    }

    &-item {
        &Heading + &Text {
            margin-top: 8px;
        }

        &.-full {
            flex: 1;
            min-width: calc(100% - 8px);
            max-width: calc(100% - 8px);
            margin-left: 8px;
            margin-bottom: 8px;

            @include at-breakpoint(phone) {
                margin-left: 0;
            }
        }

        &.-card {
            flex: 1;
            min-width: calc(25% - 8px);
            max-width: calc(25% - 8px);
            margin-left: 8px;
            margin-bottom: 8px;

            @include at-breakpoint(desk) {
                min-width: calc(33.3% - 8px);
                max-width: calc(33.3% - 8px);
            }

            @include at-breakpoint(tablet) {
                min-width: calc(50% - 8px);
                max-width: calc(50% - 8px);
            }

            @include at-breakpoint(phone) {
                min-width: unset;
                max-width: none;
                margin-left: 0;
            }
        }

        &.-expandable {
            margin-bottom: 8px;
            border-radius: 16px;
            overflow: hidden;

            #{$component-base}-item {
                &Base {
                    background: $color-white;
                    min-height: 112px;
                    display: flex;
                    overflow: hidden;
                    transition: all .5s ease;

                    &.-slim {
                        min-height: auto;
                    }

                    @include at-breakpoint(tablet) {
                        display: block;
                        height: auto;
                    }
                }

                &Date {
                    min-width: 149px;
                    max-width: 149px;
                    width: 100%;
                    background: $color-gray-fill-100;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    i {
                        color: $color-primary-fill-500;
                    }

                    span {
                        @include body-large-normal;
                        font-weight: 700;
                        display: block;
                        margin-top: 8px;
                    }

                    @include at-breakpoint(tablet) {
                        min-width: unset;
                        max-width: none;
                        min-height: 112px;
                    }
                }

                &Question {
                    min-width: 149px;
                    max-width: 149px;
                    width: 100%;
                    background: $color-gray-fill-100;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    i {
                        color: $color-primary-fill-500;
                    }

                    span {
                        @include body-large-normal;
                        font-weight: 700;
                        display: block;
                        margin-top: 8px;
                    }

                    @include at-breakpoint(tablet) {
                        min-width: unset;
                        max-width: none;
                        min-height: 60px;
                    }
                }

                &Answer {
                    min-width: 149px;
                    max-width: 149px;
                    width: 100%;
                    background: $color-gray-fill-100;
                    align-self: stretch;

                    @include at-breakpoint(tablet) {
                        display: none;
                    }
                }

                &More {
                    display: none;
                    background: $color-white;
                    align-content: center;

                    i {
                        display: inline-block;
                        margin-top: 8px;
                    }

                    &.-success {
                        background: $color-success-fill-50;

                        i {
                            color: $color-success-fill-500 !important;
                        }
                    }

                    &.-danger {
                        background: $color-danger-fill-50;

                        i {
                            color: $color-danger-fill-500 !important;
                        }
                    }

                    &Content {
                        display: flex;
                        align-items: center;
                        padding: 40px;

                        @include at-breakpoint(tablet) {
                            padding: 24px;
                            display: block;
                        }

                        &.-slim {
                            padding: 0;
                        }

                        &Text {
                            @include body-base-normal;
                            padding: 24px;
                            max-width: 750px;

                            .Button {
                                margin-top: 8px;
                            }

                            &.-noPaddingTop {
                                padding-top: 0;
                            }

                            & .-danger {
                                color: $color-danger-fill-500;
                                font-weight: bold;
                                margin-top: 8px;
                            }
                        }
                    }
                }

                &Status {
                    min-width: 149px;
                    max-width: 149px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include at-breakpoint(tablet) {
                        margin: auto;
                        padding-top: 24px;
                    }

                    & > span {
                        @include body-large-normal;
                        font-weight: bold;
                    }
                }

                &Content {
                    flex: 1;
                    padding: 24px;

                    &.-center {
                        display: flex;
                        align-items: center;
                    }
                }

                &Heading {
                    @include heading-2xl;

                    @include at-breakpoint(tablet) {
                        @include heading-xl;
                    }
                }

                &Heading2 {
                    @include heading-2xl;
                    margin-bottom: 8px;
                }

                &ShowMore {
                    @include reset-link;
                    @include body-small-loose;
                    width: 196px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-gray-fill-300;
                    transition: background .5s ease;

                    i {
                        margin-left: 8px;
                        transition: transform .5s ease;
                    }

                    @include at-breakpoint(tablet) {
                        height: 56px;
                        width: 100%;
                    }
                }

                &Body {
                    max-width: 768px;

                    a {
                        @include defaultlink;
                    }

                    p, li {
                        @include body-giant-normal;
                        margin-bottom: 8px;

                        @include at-breakpoint(tablet) {
                            @include body-base-normal;
                            margin-bottom: 8px;
                        }
                    }
                }

                &Text {
                    &:not(.-list) {
                        @include body-base-normal;

                        @include at-breakpoint(tablet) {
                            @include body-base-loose;
                        }
                    }

                    i {
                        color: $color-primary-fill-500;
                        margin-right: 8px;
                        margin-top: 5px;
                    }

                    &.-list {
                        display: flex;
                        align-items: flex-start;

                        @include body-large-normal;
                        margin-left: 32px;
                        min-width: calc(50% - 32px);
                        max-width: calc(50% - 32px);
                        margin-bottom: 16px;

                        @include at-breakpoint(tablet) {
                            min-width: auto;
                            max-width: none;
                        }

                        i {
                            margin-top: 4px;
                        }
                    }
                }

                &Contact {
                    display: inline-block;
                    border: 2px solid $color-gray-fill-300;
                    border-radius: 16px;
                    padding: 16px 32px 32px;
                    margin-top: 40px;


                    @include at-breakpoint(tablet) {
                        display: block;
                    }

                    &Legend {
                        @include heading-xl;
                        padding: 0 16px;
                        margin-left: -16px;
                    }

                    &Row {
                        @include body-large-normal;
                        display: flex;
                        align-items: center;

                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }

                        i {
                            margin-right: 8px;
                            font-size: 21px;
                        }

                        &.-yellow {
                            i {
                                color: $color-primary-fill-800;
                            }

                            a {
                                @include reset-link;
                                @include body-large-tight;
                                font-weight: bold;
                                color: $color-primary-fill-800;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.open & {
            /*            &Base {
                border-radius: 16px 16px 0 0;
            }

            &More {
                border-radius: 0 0 16px 16px;
            }*/

            &ShowMore {
                background: $color-white;

                & i {
                    transform: scaleY(-1)
                }
            }
        }
    }

    &.-search {
        display: block;
        margin-left: 0;
        padding: 0;

        #{$component-base}-item {
            @include reset-link;
            display: block;
            cursor: pointer;
            padding: 19px 0;

            &:not(:first-child) {
                border-top: 1px solid $color-gray-fill-300;
            }

            &Category {
                @include body-base-normal;
                font-weight: bold;
                color: $color-primary-fill-800;
            }

            &Text {
                @include body-base-normal;
            }

            &:hover {
                #{$component-base}-itemText {
                    text-decoration: underline;
                }
            }
        }
    }

    &.-contact {

        #{$component-base}-item {
            margin-bottom: 8px;

            &Toggle {
                padding: 19px 16px;
                display: flex;
                background: $color-gray-fill-300;
                border-radius: 16px;
                cursor: pointer;

                @include at-breakpoint(tablet) {
                    padding: 16px;
                }

                & > i {
                    line-height: 24px;

                    @include at-breakpoint(tablet) {
                        font-size: 0.9em;
                    }
                }

                &.collapsed {
                    & > i {
                        transform: scaleY(-1)
                    }
                }

                span {
                    @include body-large-normal;
                    font-weight: bold;
                    flex: 1;
                    pointer-events: none;

                    @include at-breakpoint(tablet) {
                        @include body-base-normal;
                        font-weight: bold;
                    }
                }
            }

            &More {
                display: none;
                margin-bottom: 64px;

                @include at-breakpoint(tablet) {
                    margin-bottom: 8px;
                }
            }

            &.open & {
                &More {
                    display: block;
                }
            }
        }
    }
}
