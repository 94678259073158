﻿/// <reference path="../__references.scss" />

$component-name: 'LinkList';
$component-base: '.#{$component-name}';

#{$component-base} {
    margin-bottom: 64px;

    &-heading {
        @include heading-5xl;
        margin-bottom: 24px;

        @include at-breakpoint(tablet) {
            @include heading-3xl;
            margin-bottom: 24px;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -32px;

        @include at-breakpoint(phone) {
            display: block;
            margin-left: 0;
        }
    }

    &-link {
        @include reset-link;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 32px;
        width: calc(33.3% - 32px);
        padding: 15px 0;
        border-bottom: 1px solid $color-gray-fill-300;


        @include at-breakpoint(tablet) {
            width: calc(50% - 32px);
        }

        @include at-breakpoint(phone) {
            width: 100%;
            margin-left: 0;
        }

        span {
            @include body-base-normal;
            font-weight: bold;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        i {
            color: $color-primary-fill-500
        }
    }
}
