﻿/// <reference path="../__references.scss" />

$component-name: 'Grid';
$component-base: '.#{$component-name}';

//Use only in this file
#{$component-base} {
    display: flex;

    @mixin grid($columns, $gap) {
        &.-columns#{$columns}.-gap#{$gap} {
            flex-wrap: wrap;
            margin-left: -#{$gap}px;

            @include at-breakpoint(tablet) {
                margin-left: 0;
            }

            #{$component-base}-item {
                flex: 1;
                margin-left: #{$gap}px;
                min-width: calc((100% / #{$columns}) - #{$gap}px);
                max-width: calc((100% / #{$columns}) - #{$gap}px);

                @include at-breakpoint(tablet) {
                    margin-left: 0;
                    min-width: 100%;
                    max-width: 100%;

                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    &.-center {
        justify-content: center;
    }

    &.-hcenter {
        align-items: center;
    }

    & + &:not(.-slim) {
        margin-top: 24px;

        @include at-breakpoint(tablet) {
            margin-top: 16px;
        }

        &.-loose {
            margin-top: 32px;

            @include at-breakpoint(tablet) {
                margin-top: 32px;
            }
        }
    }

    @include at-breakpoint(tablet) {
        display: block;
    }

    @include grid(2,8); //Grid -columns2 -gap8
    @include grid(2,24); //Grid -columns2 -gap24
    @include grid(2,32); //Grid -columns2 -gap32
    @include grid(3,8); //Grid -columns3 -gap8
    @include grid(3,24); //Grid -columns3 -gap24
    @include grid(4,8); //Grid -columns4 -gap8
    @include grid(4,24); //Grid -columns4 -gap24
}


