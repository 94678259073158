﻿/// <reference path="../__references.scss" />

$component-name: 'Login';
$component-base: '.#{$component-name}';

#{$component-base} {
    max-width: 880px;
    width: 100%;
    margin: 0 auto;
    @include background-opacity($color-white, 0.95);
    text-align: center;
    padding: 40px;

    @include at-breakpoint(tablet) {
        margin: 0 16px;
        width: auto;
        padding: 24px;
    }

    @include at-breakpoint(phone) {
        margin: 0;
    }

    &-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        & > i {
            color: $color-primary-fill-500;
            margin-right: 8px;
        }

        & > span {
            @include heading-4xl;
        }
    }

    &-heading2 {
        @include heading-2xl;
        margin-bottom: 40px;
    }
}


