﻿/// <reference path="../__references.scss" />

$component-name: 'Event';
$component-base: '.#{$component-name}';

#{$component-base} {
    background: $color-white;
    padding: 64px 40px;
    max-width: 1216px;
    width: 100%;

    &-info {
        @include body-giant-normal;
        font-weight: bold;
    }

    &-logo {
        width: 187px;
        height: 80px;
    }

    &-inforows {
        @include at-breakpoint(tablet) {
            display: flex !important;
            margin-left: -8px !important;

            .Grid-item {
                margin-top: 16px;
                margin-left: 8px !important;
                min-width: calc((100% / 2) - 8px) !important;
                max-width: calc((100% / 2) - 8px) !important;
            }
        }

        @include at-breakpoint(phone) {
            .Grid-item {
                min-width: 100% !important;
                max-width: 100% !important;
            }
        }
    }

    @include at-breakpoint(tablet) {
        & .Button {
            margin-top: 16px;
        }
    }

    @include at-breakpoint(phone) {
        &-date {
            display: block;
        }
    }


    i {
        color: $color-primary-fill-500;
    }
}
