﻿/// <reference path="../__references.scss" />

$component-name: 'Breadcrumbs';
$component-base: '.#{$component-name}';

#{$component-base} {
    padding: 30px 0;

    &.-gray {
        background: $color-gray-fill-200;
    }

    &-list {
        @include reset-list;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-item {
        display: flex;
        align-items: center;

        & > span {
            @include body-small-loose;
            color: $color-gray-fill-500;
            white-space: nowrap;
        }

        &:before {
            color: $color-gray-fill-500;
            font-size: 14px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    &-link {
        @include reset-link;
        @include body-small-loose;
        color: $color-primary-fill-800;
        text-decoration: none;

        &:not(.-icon) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
