﻿/// <reference path="../__references.scss" />

$component-name: 'Search';
$component-base: '.#{$component-name}';

#{$component-base} {
    &-overlay {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9998;
        transform: translateX(-100vw);
        transition: opacity .5s ease;
        @include background-opacity($color-gray-fill-500, 0.5);

        &.-active {
            opacity: 1;
            top: 0;
            transform: translateX(0);
        }
    }

    &-modal {
        background: $color-gray-fill-200;
        width: 100%;
        max-width: 1088px;
        margin: auto;
        margin-top: 80px;

        &Header {
            padding: 16px 24px;
        }

        &Form {
            padding: 64px;

            @include at-breakpoint(tablet) {
                padding: 16px 24px;
            }
        }
    }

    &-form {
        input {
            padding: 20px 16px;
            max-height: none !important;
        }

        .Button {
            padding: 20px 40px;
        }
    }

    &-heading {
        display: flex;
        align-items: center;
        margin: 0;

        &.-page {
            margin-bottom: 24px;
        }

        & > i {
            margin-right: 8px;
            font-size: 22px;
        }

        Button {
            & > i {
                font-size: 16px;
                font-weight: normal;
                margin-right: 8px;
                line-height: 16px;
            }
        }

        span {
            @include heading-2xl;
            flex: 1;
        }
    }

    &-results {
        display: flex;
        align-items: flex-end;

        &Text {
            @include heading-2xl;
            flex: 1;

            @include at-breakpoint(tablet) {

                @include heading-xl;
            }
        }

        &Count {
            @include body-small-loose;
        }
    }
}
