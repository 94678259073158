﻿/// <reference path="../__references.scss" />

$component-name: 'Filter';
$component-base: '.#{$component-name}';

#{$component-base} {
    margin: 0 0 64px;

    &.-nomargin {
        margin-left: 0;
        margin-right: 0;
    }

    @include at-breakpoint(desk) {
        margin: 0 0 32px;
    }

    @include at-breakpoint(tablet) {
        padding-top: 32px;
        margin: 0;
    }

    &-toggler {
        display: none;
        align-items: center;
        background: $color-gray-fill-300;
        border-radius: 8px;
        padding: 8px 16px;
        margin-bottom: 24px;

        & > span {
            @include body-small-loose;
            flex: 1;
        }

        & > i {
            line-height: 15px;
        }

        &.collapsed > i {
            transform: scaleY(-1);
        }

        @include at-breakpoint(tablet) {
            display: flex;
        }
    }

    &-togglecontent {
        display: block;

        @include at-breakpoint(tablet) {
            display: none;
        }
    }

    &-content {
        display: flex;
        margin-left: -32px;

        @include at-breakpoint(tablet) {
            margin-left: 0;
            display: block;
            margin-bottom: 32px;
        }

        &.-tags {
            margin-left: 0;
            flex-wrap: wrap;
        }
    }

    &-tag {
        @include reset-link;
        @include body-base-normal;
        display: block;
        border: 1px solid $color-gray-fill-900;
        padding: 8px 16px;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 20px;

        @include at-breakpoint(tablet) {
            display: block;
            margin-right: 0;
            width: 100%;
        }

        &:hover {
            text-decoration: underline;
        }

        &.-selected {
            background: $color-gray-fill-900;
            color: $color-white;
        }
    }

    &-fieldset {
        flex: 1;
        margin-left: 32px;
        border: 2px solid $color-gray-fill-300;
        border-radius: 16px;
        padding: 12px 32px 32px;

        @include at-breakpoint(tablet) {
            margin-left: 0;
            padding: 12px 16px 32px;

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    &-legend {
        @include heading-xl;
        padding: 8px 18px;
        margin-left: -18px;

        @include at-breakpoint(tablet) {
            margin-left: 0;
            text-align: center;
        }
    }

    &-heading {
        @include heading-3xl;
        margin-bottom: 24px;
    }


    &-row {
        display: flex;
        margin-left: -24px;

        @include at-breakpoint(phone) {
            display: block;
            margin-left: 0;
        }
    }

    &-control {
        flex: 1;
        margin-left: 24px;

        @include at-breakpoint(phone) {
            margin-left: 0;

            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    &-label {
        display: block;
        width: 100%;
    }

    &-select {
        display: block;
        width: 100%;
    }

    &-input {
        display: block;
        width: 100%;
    }
}
