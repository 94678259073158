﻿@import "Variables.scss";

@mixin at-breakpoint($alias) {
    $query: map-get($breakpoints, $alias);

    @if $query {
        @media #{$query} {
            @content;
        }
    }

    @else {
        @error "No breakpoint found for #{$alias}";
    }
}

@mixin font-size($size, $line-height: "") {
    font-size: #{$size}px;
    font-size: #{$size / 10}rem;

    @if ($line-height != "") {
        line-height: #{$line-height}px;
        line-height: #{$line-height / 10}rem;
    }
}

@mixin reset-list() {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin reset-link {
    text-decoration: none;
    color: inherit;
}

@mixin reset-button {
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
@mixin border-opacity($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}

@mixin create-class-at-breakpoints($class) {
    #{$class} {
        @content;
    }

    @each $name, $declaration in $breakpoints {
        $final-selector: #{$class}-#{$name};

        @include at-breakpoint($name) {
            #{$final-selector} {
                @content;
            }
        }
    }
}

