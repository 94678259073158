﻿/// <reference path="../__references.scss" />

$component-name: 'ImageText';
$component-base: '.#{$component-name}';

#{$component-base} {
    position: relative;
    display: flex;
    justify-content: center;

    @include at-breakpoint(tablet) {
        display: block;
        margin: -24px -16px 0;
    }

    &-imagecontainer {
        flex: 1 1;
        max-width: 1216px;

        @include at-breakpoint(tablet) {
            max-width: none;
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    &-content {
        flex: 1 1;
        margin-left: -30%;
        max-width: 1216px;
        position: relative;
        padding-top: 80px;

        @include at-breakpoint(tablet) {
            max-width: none;
            min-height: auto;
            margin-top: -53px !important;
            margin-left: 16px !important;
            margin-right: 16px !important;
            position: relative;
            padding-top: 0 !important;
        }

        &-inner {
            @include background-opacity($color-white, 0.9);
            padding: 64px 224px;
            min-height: 405px;

            @include at-breakpoint(desk) {
                padding: 64px;
                min-height: auto;
            }

            @include at-breakpoint(phone) {
                padding: 24px;
                text-align: center;
            }
        }

        & > :first-child {
            margin-top: 0;
        }
    }

    &-heading {

        &:not(.-small) {
            @include heading-6xl;
            margin: 0 auto 16px;

            @include at-breakpoint(phone) {
                @include heading-3xl;
                margin: 0 0 16px;
            }
        }

        &.-small {
            max-width: 528px;
            @include heading-5xl;
            margin: 0 auto 24px;

            @include at-breakpoint(phone) {
                @include heading-3xl;
                margin: 0 auto 24px;
            }
        }
    }

    &.-bigImage & {
        &-imagecontainer {
            flex: 2 2;
            position: relative;

            @include at-breakpoint(desk) {
                flex: 1 1;
            }
        }

        &-content {
            margin-left: -10%;
            padding-top: 64px;
            text-align: center;

            &-inner {
                padding: 82.5px 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                & > div {
                    flex: 1;

                    & > :first-child {
                        margin-top: 0;
                    }

                    & > :last-child {
                        margin-bottom: 0;
                    }
                }



                @include at-breakpoint(desk) {
                    padding: 82.5px 40px;
                }

                @include at-breakpoint(phone) {
                    padding: 40px;
                    text-align: center;
                }
            }
        }
    }

    &-logo {
        position: absolute;
        top: 100px;
        left: calc(50% - 176.5px);
        width: 353px;
        height: 204px;

        @include at-breakpoint(max) {
            top: 100px;
            left: calc(50% - 115px);
            width: 230px;
            height: 133px;
        }

        @include at-breakpoint(tablet) {
            top: calc(50% - 102px);
        }

        @include at-breakpoint(phone) {
            left: calc(50% - 69px);
            top: calc(50% - 40px);
            width: 138px;
            height: 80px;
        }
    }

    &-logoLicense {
        position: absolute;
        top: 90px;
        left: calc(50% - 73px);
        width: 146px;
        height: 224px;

        @include at-breakpoint(max) {
            top: 90px;
            left: calc(50% - 60px);
            width: 120px;
            height: 184px;
        }

        @include at-breakpoint(tablet) {
            top: calc(50% - 100px);
        }

        @include at-breakpoint(phone) {
            height: 112px;
            left: calc(50% - 36px);
            top: calc(50% - 80px);
            width: 73px;
        }
    }

    &-icon {
        color: $color-primary-fill-500;
    }

    &-intro {
        @include lead-giant;
        margin-bottom: 16px;

        @include at-breakpoint(phone) {
            @include heading-xl;
            margin-bottom: 16px;
        }
    }

    &-lead {
        @include lead-giant;
        margin-bottom: 16px;

        @include at-breakpoint(phone) {
            @include lead-base;
            margin-bottom: 16px;
        }
    }

    &-body {
        @include body-giant-normal;
        margin-bottom: 40px;

        @include at-breakpoint(phone) {
            @include lead-base;
            font-weight: bold;
            margin-bottom: 40px;
        }
    }

    &-link {
        margin-top: 40px;
    }

    &-checklist {
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            @include body-large-normal;
            display: flex;
            align-items: flex-start;
            margin-top: 16px;
            text-align: left;

            i {
                color: $color-primary-fill-500;
                margin-right: 8px;
                margin-top: 3px;
            }
        }
    }
}
