﻿/// <reference path="../__references.scss" />

$component-name: "Animation";
$component-base: ".#{$component-name}";

#{$component-base} {
    &-knockRight {
        &:hover {
            & > i, & #{$component-base}-icon {
                animation: knock-right 3000ms ease-in-out infinite;
            }
        }
    }

    &-knockLeft {
        &:hover {
            & > i, & #{$component-base}-icon {
                animation: knock-left 3000ms ease-in-out infinite;
            }
        }
    }

    &-bounce {
        &:hover {
            & > i, & #{$component-base}-icon {
                animation: bounce 3000ms ease-in-out infinite;
            }
        }
    }

    &-heartbeat {
        &:hover {
            & > i, & #{$component-base}-icon {
                animation: knock-right 3000ms ease-in-out infinite;
            }
        }
    }

    &-slideUp {
        transform: translateY(150px);

        &.come-in {
            transform: translateY(150px);
            opacity: 0;
            animation: come-in 0.8s ease-out forwards;
        }

        &.already-visible {
            transform: translateY(0);
            opacity: 1;
            animation: none;
        }
    }

    &-slideInRight {
        transform: translateX(-50px);
        opacity: 0;

        &.come-in {
            transform: translateX(-50px);
            opacity: 0;
            animation: come-in-horizontal 0.5s ease-out forwards;
        }

        &.already-visible {
            transform: translateX(0);
            opacity: 1;
            animation: none;
        }
    }

    &-slideInLeft {
        transform: translateX(50px);
        opacity: 0;

        &.come-in {
            transform: translateX(50px);
            opacity: 0;
            animation: come-in-horizontal 0.5s ease-out forwards;
        }

        &.already-visible {
            transform: translateX(0);
            opacity: 1;
            animation: none;
        }
    }

    &-glideIn {
        transform: scale(105%);

        &.come-in {
            transform: scale(105%);
            animation: glide-in 0.5s ease-out forwards;
        }

        &.already-visible {
            transform: scale(100%);
            animation: none;
        }
    }

    &-linkImageScale {
        & img {
            transition: transform .1s
        }

        &:hover {
            & img {
                transition: transform 4s;
                transform: scale(1.05);
            }
        }
    }
}

@keyframes come-in {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes come-in-horizontal {
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes glide-in {
    to {
        transform: scale(100%);
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(1.15);
    }

    25% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.15);
    }

    35% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1,1) translateY(0);
    }

    10% {
        transform: scale(1.1,.9) translateY(0);
    }

    30% {
        transform: scale(.9,1.1) translateY(-5px);
    }

    50% {
        transform: scale(1.05,.95) translateY(0);
    }

    57% {
        transform: scale(1,1) translateY(-7px);
    }

    64% {
        transform: scale(1,1) translateY(0);
    }

    100% {
        transform: scale(1,1) translateY(0);
    }
}

@keyframes knock-right {
    0%, 5%, 15%, 25%, 100% {
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -ms-transform: translateX(-2px);
        transform: translateX(-2px);
    }

    20% {
        -ms-transform: translateX(-4px);
        transform: translateX(-4px);
    }
}


@keyframes knock-right-slow {
    0%, 5%, 15%, 25%, 100% {
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -ms-transform: translateX(-1px);
        transform: translateX(-1px);
    }

    20% {
        -ms-transform: translateX(-2px);
        transform: translateX(-2px);
    }
}

@keyframes knock-left {
    0%, 5%, 15%, 25%, 100% {
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    10% {
        -ms-transform: translateX(2px);
        transform: translateX(2px);
    }

    20% {
        -ms-transform: translateX(4px);
        transform: translateX(4px);
    }
}
