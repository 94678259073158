﻿/// <reference path="../__references.scss" />

$component-name: 'Teaser';
$component-base: '.#{$component-name}';

#{$component-base} {
    flex: 1;
    max-width: 640px;
    margin-left: 8px;

    @at-root {
        a#{&} {
            @include reset-link;
            cursor: pointer;
            display: block;

            &:hover {
                .Link {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }


    @include at-breakpoint(phone) {
        margin-left: 0;
        max-width: none;
        margin-bottom: 8px;
    }

    &.-white {
        background: $color-white;
    }

    &-container {
        display: flex;
        justify-content: center;
        margin-left: -8px;

        &.-middle {
            align-items: center;
        }

        @include at-breakpoint(phone) {
            display: block;
            margin-left: 0;
        }
    }

    &-line {
        display: flex;
        justify-content: center;
        align-items: center;

        &Line {
            height: 1px;
            background: $color-gray-fill-300;
            width: 35px;

            &.-full {
                width: auto;
                flex: 1;
            }

            &.-gray {
                background: $color-gray-fill-400;
            }
        }

        &Logo {
            background-image: url('/images/LogoBird.svg');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            margin: 0 16px;
        }

        &Text {
            @include body-small-normal;
            font-weight: bold;
            margin: 15px 16px;
            text-transform: uppercase;
            text-align: center;

            &.-big {
                @include body-base-normal;
                font-weight: bold;
            }

            & > span {
                white-space: nowrap;
            }

            @include at-breakpoint(tablet) {
                display: flex;
                flex-direction: column;
            }
        }


        &.-thin {
            #{$component-base}-line {
                &Text {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-content {
        text-align: center;
        padding: 20px 24px 40px;

        @include at-breakpoint(phone) {
            padding: 24px;
        }
    }

    &-heading {
        @include heading-3xl;
        margin-bottom: 32px;
        margin-top: 16px;

        @include at-breakpoint(phone) {
            @include heading-2xl;
            margin-bottom: 32px;
            margin-top: 16px;
        }
    }

    &-intro {
        @include body-base-normal;
        margin-bottom: 32px;
    }
}
