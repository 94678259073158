﻿/// <reference path="../__references.scss" />

$component-name: 'Block';
$component-base: '.#{$component-name}';

#{$component-base} {
    padding: 64px 0;

    @include at-breakpoint(tablet) {
        padding: 24px 0;
    }

    &.-tight {
        padding: 19px 0 24px;
        min-height: 98px;

        @include at-breakpoint(tablet) {
            padding: 19px 0 24px;
        }
    }

    &.-white {
        background: $color-white;

        @include at-breakpoint(phone) {
            margin: 0 0 16px;
            padding: 16px 0;
        }
    }

    &.-login {
        background-size: cover;

        @include at-breakpoint(tablet) {
            padding: 16px 64px;
        }

        @include at-breakpoint(phone) {
            padding: 16px;
        }
    }

    &.-gray {
        background: $color-gray-fill-200;
    }

    &.-lightgray {
        background: $color-gray-fill-100;
    }

    &.-gray + &.-gray {
        padding-top: 0;
    }

    &.-blue {
        background: $color-misc-blue;

        @include at-breakpoint(tablet) {
            background: $color-gray-fill-200;
        }
    }

    &.-stormyazur {
        background: $color-misc-stormy-azur;

        @include at-breakpoint(tablet) {
            background: $color-gray-fill-200;
        }
    }

    &.-rosypearl {
        background: $color-misc-rosy-pearl;

        @include at-breakpoint(tablet) {
            background: $color-gray-fill-200;
        }
    }

    &.-slim {
        padding-top: 0;

        &:not(:last-child) {
            padding-bottom: 0;
        }
    }

    &.-slimtop {
        padding-top: 0;
    }

    &.-pattern {
        background-image: url('/images/patterns/ff-pattern-gray200-with-primary500-15.svg');
        background-position: left top;
    }

    &-inner {
        max-width: 1216px;
        margin: 0 auto;

        &.-full {
            max-width: none;

            @include at-breakpoint(max) {
                margin: 0;
            }
        }

        &.-small {
            max-width: 860px;
        }
    }

    &.-macro {
        margin: 80px calc((768px - 1216px) / 2);
        padding: 64px;

        @include at-breakpoint(desk) {
            margin: 48px 0;
            padding: 24px;
        }
    }
}


.-bluecontent {
    #{$component-base}.-pattern {
        background-image: url('/images/patterns/ff-pattern-arets-frisor-blue-with-primary900.svg');
    }
}
.-stormyazurcontent {
    #{$component-base}.-pattern {
        background-image: url('/images/patterns/ff-pattern-frisorlicense-stormyazur-with-primary900.svg');
    }
}




