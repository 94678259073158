﻿/// <reference path="../__references.scss" />

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: $font-family-lato;
    line-height: 32px;
    position: relative;
    min-height: 100%;
    padding: 0;
    background-color: $color-white;
    color: $color-gray-fill-900;
    scroll-behavior: smooth;
}

body {
    font-size: 2.2rem;
    min-height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;

    &.freeze {
        overflow: hidden;
    }
}

main {
    flex: 1;
}

figure {
    padding: 0;
    margin: 0;
}

p {
    &:not([class]) {
        margin: 0;
        margin-bottom: 1em;
        line-height: 1.4;

        &:last-child {
            margin: 0;
        }
    }
}

img, svg {
    max-width: 100%;
    vertical-align: middle;
}

iframe {
    max-width: 100%;
}

.grecaptcha-badge {
    opacity: 0;
}

[class^="ri-"],
[class*=" ri-"] {
    transition: all .2s;
}

fieldset {
    legend {
        
    }
}

.-textRight {
    text-align: right;
}
.-textCenter {
    text-align: center;
}

$gutter-size-value: 8;

@for $i from 1 through 8 {
    @include create-class-at-breakpoints(".Margin#{$i * $gutter-size-value}") {
        margin: #{$i * $gutter-size-value}px !important;
    }

    @include create-class-at-breakpoints(".Padding#{$i * $gutter-size-value}") {
        padding: #{$i * $gutter-size-value}px !important;
    }

    $directions: "Top", "Right", "Bottom", "Left";

    @each $direction in $directions {
        @include create-class-at-breakpoints(".Margin#{$direction}#{$i * $gutter-size-value}") {
            margin-#{to-lower-case($direction)}: #{$i * $gutter-size-value}px !important;
        }

        @include create-class-at-breakpoints(".Padding#{$direction}#{$i * $gutter-size-value}") {
            padding-#{to-lower-case($direction)}: #{$i * $gutter-size-value}px !important;
        }
    }
}



