﻿/// <reference path="../__references.scss" />

$component-name: 'Form';
$component-base: '.#{$component-name}';

@mixin required {
    background-image: url('/images/remixicons/asterisk.svg');
    background-repeat: no-repeat;
    background-position: right 17px center;
    background-size: 16px;
}

@mixin error {
    background-image: url('/images/remixicons/error-warning-line.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 20px;
    border-color: $color-danger-fill-500;
    padding-right: 45px;
}

@mixin success {
    background-image: url('/images/remixicons/checkbox-circle-line.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 20px;
    border-color: $color-success-fill-500;
    padding-right: 45px;
}

#{$component-base} {


    &-label {
        &:not(.-checkbox) {
            @include body-small-loose;
            font-weight: bold;
        }

        &.-checkbox {
            @include body-small-normal;
            display: block;
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-top: 16px;

            & > input {
                position: absolute;
                cursor: pointer;
                left: 8px;
                top: 13px;
                height: 2px;
                width: 2px;

                &:not(.-showinput) {
                    opacity: 0;
                    height: 0;
                    width: 0;
                }

                &:checked ~ .checkmark {
                    background-color: $color-primary-fill-500;

                    &:after {
                        display: block;
                    }
                }
            }

            & > .checkmark {
                position: absolute;
                top: 3px;
                left: 0;
                height: 15px;
                width: 15px;
                background-color: $color-white;
                border: 1px solid $color-gray-fill-500;

                @include at-breakpoint(tablet) {
                    height: 17px;
                    width: 17px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 3px;
                    top: 0px;
                    width: 5px;
                    height: 8px;
                    border: solid $color-gray-fill-800;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);

                    @include at-breakpoint(tablet) {
                        left: 4px;
                        height: 10px;
                    }
                }
            }
        }

        &.-radio {
            @include body-small-normal;
            display: block;
            position: relative;
            padding-left: 24px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-top: 16px;

            & > input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: $color-primary-fill-500;

                    &:after {
                        display: block;
                    }
                }
            }

            & > .checkmark {
                position: absolute;
                top: 3px;
                left: 0;
                height: 15px;
                width: 15px;
                background-color: $color-white;
                border: 1px solid $color-gray-fill-500;
                border-radius: 100%;


                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 2px;
                    top: 2px;
                    width: 9px;
                    height: 9px;
                    background: $color-gray-fill-800;
                    border-radius: 100%;

                    @include at-breakpoint(tablet) {
                        height: 18px;
                        width: 18px;
                    }
                }
            }
        }
    }

    &-control {
        text-align: left;

        &.-onerow {
            #{$component-base} {
                &-label, &-text {
                    display: inline;
                }
            }
        }

        &.-bigger {
            #{$component-base} {
                &-label {
                    @include body-huge-loose;
                    font-weight: bold;
                }

                &-text {
                    @include body-huge-loose;
                }
            }
        }
    }

    &-heading {
        @include heading-3xl;
        margin-top: 24px;

        @include at-breakpoint(tablet) {
            @include heading-large;
            margin-top: 24px;
        }
    }

    &-legend {
        font-family: $font-family-playfair;
        color: $color-gray-fill-900;
        margin: 0;
        @include font-size(20,28);
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.01em;
        padding: 0px 18px;
        margin-left: -18px;

        @include at-breakpoint(tablet) {
            text-align: center;
        }

        &Extra {
            color: $color-primary-fill-900;

            @include at-breakpoint(tablet) {
                display: none;
            }

            &.-green {
                color: $color-success-fill-500;
            }
        }
    }

    &-fieldset {
        border: 2px solid $color-gray-fill-300;
        border-radius: 16px;
        padding: 12px 32px 32px;
        margin-top: 24px;

        @include at-breakpoint(tablet) {
            padding: 12px 16px 32px;
        }

        &.-success {
            @include background-opacity($color-success-fill-500, 0.2);
            color: $color-success-fill-700;
            @include background-opacity($color-success-fill-500, 0.1);

            & > h4 {
                margin-bottom: 0;
                margin-top: 16px;
            }
        }

        &.-editModeDisabled {
            border: 2px solid $color-gray-fill-100;

            #{$component-base} {
                &-input, &-select {
                    @include body-base-loose;
                    padding: 0;
                    border: 0;
                    border-radius: 0;
                    opacity: 1 !important;
                    background-image: none;

                    &Container {
                        &:after {
                            content: ''
                        }
                    }
                }
            }
        }
    }

    &-text {
        @include body-base-loose;

        &.-success {
            color: $color-success-fill-500;
        }

        &.-error {
            color: $color-danger-fill-500;
        }
    }

    &-input {
        @include body-small-loose;
        padding: 8px 44px 8px 16px;
        border-radius: 8px;
        background: $color-white;
        border: 1px solid $color-gray-fill-300;
        width: 100%;
        position: relative;

        &:not(.-textArea) {
            max-height: 40px;
        }

        &.-textArea {
            max-width: 100%;
            min-width: 100%;
            min-height: 96px;
        }

        &.-required {
            @include required;
        }

        &.-noborder {
            border: 0;
        }

        &.-date {
            padding: 8px 16px 8px 16px;
            -webkit-appearance: none;

            &::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url('/images/icons/calendar-2-line.png') no-repeat;
                background-size: cover;
                width: 15px;
                height: 15px;
                border-width: thin;
            }
        }

        &.invalid, &.input-validation-error {
            @include error;
        }

        &.modified.valid {
            @include success;
        }

        &ButtonContainer {
            display: flex;

            #{$component-base}-input {
                border-radius: 16px 0px 0px 16px;
            }

            .Button {
                border-radius: 0px 16px 16px 0px;
                white-space: nowrap;
            }
        }

        &Container {
            position: relative;

            &.-date {
                input {
                    &.-required {
                        background-position: right 40px center;
                    }

                    &.invalid, &.input-validation-error {
                        background-position: right 40px center;
                    }

                    &.modified.valid {
                        background-position: right 40px center;
                    }
                }
            }
        }

        &::-webkit-input-placeholder { /* Edge */
            @include placeholder;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            @include placeholder;
        }

        &::placeholder {
            @include placeholder;
        }
    }

    &-select {
        @include body-small-loose;
        padding: 8px 44px 8px 16px;
        background: $color-white;
        border: 1px solid $color-gray-fill-300;
        -webkit-appearance: none;
        border-radius: 8px;
        width: 100%;

        &.-required {
            @include required;
        }

        &.invalid, &.input-validation-error {
            @include error;
        }

        &.modified.valid {
            @include success;
        }

        &Container {
            position: relative;

            select {
                &.-required {
                    background-position: right 40px center;
                }

                &.invalid, &.input-validation-error {
                    background-position: right 40px center;
                }

                &.modified.valid {
                    background-position: right 40px center;
                }
            }

            &::after {
                content: '\ea4d';
                font-family: 'remixicon' !important;
                font-style: normal;
                font-size: 28px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 10px;
                bottom: 8px;
                pointer-events: none;
            }
        }
    }

    &-validation {
        @include body-small-tight;
        display: block;
        margin-top: 4px;
        font-weight: bold;
        color: $color-danger-fill-700
    }

    &-example {
        @include body-small-tight;
        color: $color-gray-fill-600;
        margin-top: 8px;
    }

    &-requiredText {
        @include body-large-normal;
        display: flex;
        align-items: center;

        i {
            color: $color-primary-fill-800;
        }
    }

    &-button {
        margin-top: 24px;

        @include at-breakpoint(tablet) {
            margin-top: 16px;
        }

        & + & {
            margin-left: 16px;
        }
    }
}

.validation-message {
    @include body-small-tight;
    display: block;
    margin-top: 4px;
    font-weight: bold;
    color: $color-danger-fill-700
}
