﻿/// <reference path="../__references.scss" />

$component-name: 'Membership';
$component-base: '.#{$component-name}';

#{$component-base} {
    padding-bottom: 8px;

    &-base {
        background: $color-white;
        border-radius: 16px;
        height: 112px;
        display: flex;
        overflow: hidden;
        transition: all .5s ease;

        @include at-breakpoint(tablet) {
            display: block;
            height: auto;
        }
    }

    &-more {
        display: none;
        background: $color-white;
        border-radius: 16px;
        align-content: center;
        padding: 40px;
        transition: border-radius .5s ease;

        @include at-breakpoint(tablet) {
            padding: 24px;
        }
    }

    &-image {
        &Container {
            width: 200px;

            @include at-breakpoint(tablet) {
                width: 100%;
            }
        }
    }

    &-content {
        flex: 1;
        padding: 24px;
    }

    &-heading {
        @include heading-2xl;
        margin-bottom: 8px;

        @include at-breakpoint(tablet) {
            @include heading-xl;
        }
    }

    &-heading2 {
        @include heading-2xl;
        margin-bottom: 8px;
    }

    &-check {
        @include body-base-normal;
    }

    &-moreInfo {
        @include reset-link;
        @include body-small-loose;
        width: 196px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-gray-fill-300;
        transition: background .5s ease;

        i {
            margin-left: 8px;
            transition: transform .5s ease;
        }

        @include at-breakpoint(tablet) {
            height: 56px;
            width: 100%;
        }
    }

    &-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -32px;
        margin-top: 32px;

        @include at-breakpoint(tablet) {
            margin-top: 24px;
            align-items: flex-start;
            flex-direction: column;
        }
    }

    &-text {
        display: flex;
        align-items: flex-start;

        &:not(.-list) {
            @include body-base-normal;

            @include at-breakpoint(tablet) {
                @include body-base-loose;
            }
        }

        i {
            color: $color-primary-fill-500;
            margin-right: 8px;
            margin-top: 5px;
        }

        &.-list {
            @include body-large-normal;
            margin-left: 32px;
            min-width: calc(50% - 32px);
            max-width: calc(50% - 32px);
            margin-bottom: 16px;

            @include at-breakpoint(tablet) {
                min-width: auto;
                max-width: none;
            }

            i {
                margin-top: 4px;
            }
        }
    }

    &-buttonRow {
        margin-top: 32px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @include at-breakpoint(tablet) {
            display: block;
            text-align: center;
        }
    }

    &-price {
        @include lead-giant;
        margin-left: 24px;

        @include at-breakpoint(tablet) {
            @include lead-base;
            margin-left: 0;
            margin-bottom: 24px;
        }
    }

    &.open & {
        &-base {
            border-radius: 16px 16px 0 0;
        }

        &-more {
            border-radius: 0 0 16px 16px;
        }

        &-moreInfo {
            background: $color-white;

            & i {
                transform: scaleY(-1)
            }
        }
    }
}
