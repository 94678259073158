﻿/// <reference path="../__references.scss" />

$component-name: 'Header';
$component-base: '.#{$component-name}';

#{$component-base} {
    background: $color-white;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0;
    height: 136px;
    z-index: 1048;
    transition: padding 0.7s ease, height 0.7s ease, top 0.7s ease;

    &.open {
        top: 0 !important;
    }

    @include at-breakpoint(desk) {
        height: 70px;
        position: fixed;
        z-index: 1049;
    }

    &-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding: 16px 0;
        width: auto;

        @include at-breakpoint(desk) {
            padding: 16px 0;
            align-items: flex-start;
        }

        @include at-breakpoint(tablet) {
            padding: 16px 0;
        }
    }


    &-logo {
        width: 110px;
        height: 104px;
        background-image: url('/images/Logo.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include at-breakpoint(desk) {
            width: 320px;
            height: 36px;
            background-image: url('/images/LogoSmall.svg');
        }

        @include at-breakpoint(tablet) {
            width: 40px;
            height: 36px;
            background-image: url('/images/LogoBird.svg');
        }
    }
}
