﻿/// <reference path="../__references.scss" />

$component-name: 'ImageGallery';
$component-base: '.#{$component-name}';

#{$component-base} {
    &-heading {
        @include heading-3xl;
        margin-bottom: 24px;

        @include at-breakpoint(tablet) {
            @include heading-xl;
            margin-bottom: 24px;
        }
    }

    & [id^="mainCarousel_"] {
        margin: 0 auto 1rem auto;
        --carousel-button-color: $color-gray-fill-900;
        --carousel-button-bg: #fff;
        --carousel-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 50%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
        --carousel-button-svg-width: 20px;
        --carousel-button-svg-height: 20px;
        --carousel-button-svg-stroke-width: 2.5;

        & .carousel__slide {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            position: relative;
            padding-bottom: 56.25%;
            background-color: #eee;

            picture {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 0;
                bottom: 0;
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }

        & .carousel__button {
            &.is-prev {
                left: -1.5rem;
            }

            &.is-next {
                right: -1.5rem;
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 4px #A78BFA;
            }
        }
    }


    & [id^="thumbCarousel_"] {
        & .carousel__slide {
            opacity: 0.5;
            padding: 0;
            margin: 0.25rem;
            width: 96px;
            height: 64px;

            &.-portrait {
                width: auto;
            }

            &.is-nav-selected {
                opacity: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }
}
