﻿/// <reference path="../__references.scss" />

$component-name: 'Ad';
$component-base: '.#{$component-name}';

#{$component-base} {
    max-width: 100%;
    width: 970px;
    margin: 0 auto;
    padding: 16px 0 34px;

    @include at-breakpoint(phone) {
        padding: 16px 8px 24px;
        width: 500px;
    }

    &.-takeover {
        max-width: 1216px;
        width: 100%;
        margin: 0 calc((100vw - 1216px) / 2) 32px;
        padding: 0;

        @include at-breakpoint(tablet) {
            margin: 0 8px 32px;
            max-width: none;
            width: auto;
            padding: 0;
        }
    }

    &-close {
        @include reset-link;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;

        @include at-breakpoint(tablet) {
            margin-right: 0;
        }

        &Row {
            position: sticky;
            top: 0;
        }

        i {
            @include body-huge-normal;
            color: $color-white;
            background: #000000b5;
            border-radius: 100%;
        }

        &:hover {
            i {
                background: #000000;
            }
        }
    }

    &-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        @include background-opacity($color-black, 0.7);
    }

    &-text {
        @include body-base-normal;
        color: $color-gray-fill-500;
        margin-bottom: 8px;
    }
}
