﻿/// <reference path="../__references.scss" />

$component-name: 'Spotify';
$component-base: '.#{$component-name}';

#{$component-base} {

    &-wrapper {
        margin-bottom: 16px;

        & .encore-dark-theme {
            border-radius: 0;
        }
    }
}
