﻿/// <reference path="../__references.scss" />

@mixin playfair-base {
    font-family: $font-family-playfair;
    color: $color-gray-fill-900;
    margin: 0;
}

@mixin lato-base {
    font-family: $font-family-lato;
    color: $color-gray-fill-900;
}

//headings
@mixin heading-8xl {
    @include playfair-base;
    @include font-size(90,128);
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.02em;
}

@mixin heading-7xl {
    @include playfair-base;
    @include font-size(80,104);
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.02em;
    color: $color-gray-fill-900;
}

@mixin heading-6xl {
    @include playfair-base;
    @include font-size(64,84);
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.01em;
}

@mixin heading-5xl {
    @include playfair-base;
    @include font-size(48,64);
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.01em;
}

@mixin heading-4xl {
    @include playfair-base;
    @include font-size(36,48);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}

@mixin heading-3xl {
    @include playfair-base;
    @include font-size(30,40);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}

@mixin heading-2xl {
    @include playfair-base;
    @include font-size(24,32);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0;
}

@mixin heading-xl {
    @include playfair-base;
    @include font-size(20,28);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
}

@mixin heading-xl-lato {
    @include font-size(20,28);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
    font-family: $font-family-lato
}

@mixin heading-large {
    @include playfair-base;
    @include font-size(18,24);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
}

@mixin heading-base {
    @include playfair-base;
    @include font-size(16,20);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
}

@mixin heading-small {
    @include playfair-base;
    @include font-size(14,20);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
}

@mixin heading-tiny {
    @include playfair-base;
    @include font-size(12,16);
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
}

//leads
@mixin lead-giant {
    @include lato-base;
    @include font-size(24,32);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-huge {
    @include lato-base;
    @include font-size(22,30);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-large {
    @include lato-base;
    @include font-size(20,28);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-base {
    @include lato-base;
    @include font-size(18,26);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-small {
    @include lato-base;
    @include font-size(16,24);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-tiny {
    @include lato-base;
    @include font-size(14,22);
    font-style: normal;
    font-weight: bold;
}

@mixin lead-micro {
    @include lato-base;
    @include font-size(12,20);
    font-style: normal;
    font-weight: bold;
}

//bodys
@mixin body-giant-normal($line-height: 32) {
    @include lato-base;
    @include font-size(22, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-giant-loose {
    @include body-giant-normal(36);
}

@mixin body-giant-tight {
    @include body-giant-normal(28);
}

@mixin body-huge-normal($line-height: 28) {
    @include lato-base;
    @include font-size(20, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-huge-loose {
    @include body-huge-normal(32);
}

@mixin body-huge-tight {
    @include body-huge-normal(24);
}

@mixin body-large-normal($line-height: 26) {
    @include lato-base;
    @include font-size(18, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-large-loose {
    @include body-large-normal(28);
}

@mixin body-large-tight {
    @include body-large-normal(20);
}

@mixin body-base-normal($line-height: 24) {
    @include lato-base;
    @include font-size(16, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-base-loose {
    @include body-base-normal(28);
}

@mixin body-base-tight {
    @include body-base-normal(20);
}

@mixin body-small-normal($line-height: 20) {
    @include lato-base;
    @include font-size(14, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-small-loose {
    @include body-small-normal(24);
}

@mixin body-small-tight {
    @include body-small-normal(16);
}

@mixin body-tiny-normal($line-height: 16) {
    @include lato-base;
    @include font-size(12, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-tiny-loose {
    @include body-tiny-normal(20);
}

@mixin body-tiny-tight {
    @include body-tiny-normal(14);
}

@mixin body-micro-normal($line-height: 14) {
    @include lato-base;
    @include font-size(10, $line-height);
    font-style: normal;
    font-weight: normal;
}

@mixin body-micro-loose {
    @include body-micro-normal(16);
}

@mixin body-micro-tight {
    @include body-micro-normal(12);
}

@mixin placeholder {
    @include body-small-loose;
    color: $color-gray-fill-500
}

@mixin defaultlinkstyle {
    color: $color-primary-fill-800;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@mixin defaultlink {
    @include body-giant-normal;

    @include at-breakpoint(tablet) {
        @include body-base-normal;
    }

    @include defaultlinkstyle;
}



h1:not([class]), .H1 {
    @include heading-6xl;

    @include at-breakpoint(tablet) {
        @include heading-3xl;
    }
}

h2:not([class]), .H2 {
    @include heading-5xl;

    @include at-breakpoint(tablet) {
        @include heading-2xl;
    }
}

h3:not([class]), .H3 {
    @include heading-4xl;

    @include at-breakpoint(tablet) {
        @include heading-xl;
    }
}

h4:not([class]), .H4 {
    @include heading-3xl;

    @include at-breakpoint(tablet) {
        @include heading-large;
    }
}

h5:not([class]), .H5 {
    @include heading-2xl;

    @include at-breakpoint(tablet) {
        @include heading-base;
    }
}

h6:not([class]), .H6 {
    @include heading-xl;

    @include at-breakpoint(tablet) {
        @include heading-small;
    }
}
