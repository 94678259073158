﻿/// <reference path="../__references.scss" />

$component-name: 'Card';
$component-base: '.#{$component-name}';

#{$component-base} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    text-align: center;
    width: 100%;
    height: 100%;

    @at-root {
        a#{&} {
            @include reset-link;
            cursor: pointer;

            &:hover {
                .Link {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-flexcontent {
        width: 100%;
    }

    &-content {
        padding: 20px 40px 32px;
    }

    &-date {
        @include body-tiny-normal;
        font-weight: 700;
        color: $color-gray-fill-500;
        margin-top: 16px;
    }

    &-heading {
        @include heading-3xl;
        margin-top: 16px;

        @include at-breakpoint(tablet) {
            @include heading-2xl;
            margin-top: 16px;
        }
    }

    &-text {
        @include body-base-normal;
        margin-top: 32px;
    }

    &-link {
        margin-bottom: 40px;
    }

    &.-gallery {
        background: $color-gray-fill-100;

        #{$component-base} {
            &-image {
                width: 100%;

                &Container {

                    @include at-breakpoint(tablet) {
                        display: block;
                        max-width: none;
                        min-width: auto;
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }

    &.-af {
        .Teaser-lineLine {
            background: $color-primary-fill-800;
        }
    }

    &.-person {
        display: flex;
        flex-direction: row;
        margin-top: 32px;

        @include at-breakpoint(tablet) {
            display: block;
            padding: 24px;
            margin-top: 8px;
        }

        #{$component-base} {
            &-image {
                width: 100%;

                &Container {
                    max-width: 120px;
                    min-width: 120px;

                    @include at-breakpoint(tablet) {
                        display: flex;
                        justify-content: center;
                        max-width: none;
                        min-width: auto;
                        margin-bottom: 24px;
                    }
                }
            }

            &-content {
                flex: 1;
                text-align: left;
                padding: 0;
                margin-left: 24px;
                min-height: 160px;

                @include at-breakpoint(tablet) {
                    text-align: center;
                    margin-left: 0;
                    margin-bottom: 24px;
                    min-height: auto;
                }
            }

            &-name {
                @include heading-xl-lato;
                margin-top: 8px;
                margin-bottom: 8px;
            }

            &-contact {
                display: flex;
                align-items: center;

                @include at-breakpoint(tablet) {
                    justify-content: center;
                }

                span {
                    @include body-small-loose;
                }

                i {
                    margin-right: 8px;
                    font-size: 0.9em;
                }

                a {
                    @include reset-link;
                    color: $color-primary-fill-800;
                    display: flex;
                    align-items: center;

                    span {
                        color: $color-primary-fill-800;
                        font-weight: bold;
                    }

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &-title {
                .Teaser-lineLine:first-child {
                    display: none;

                    @include at-breakpoint(tablet) {
                        display: block;
                    }
                }

                .Teaser-lineText {
                    margin-left: 0;

                    @include at-breakpoint(tablet) {
                        margin-left: 16px;
                    }
                }
            }
        }
    }
}
