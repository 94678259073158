﻿/// <reference path="../__references.scss" />

$component-name: 'Footer';
$component-base: '.#{$component-name}';

#{$component-base} {
    background: $color-gray-fill-200;
    margin-top: 210.5px;

    @include at-breakpoint(tablet) {
        margin-top: 412px
    }

    @include at-breakpoint(phone) {
        margin-top: 404px;
    }

    &-inner {
        margin: 128px 0;

        @include at-breakpoint(phone) {
            margin: 64px 0;
        }
    }

    &-heading {
        @include heading-3xl;
        margin-bottom: 16px;

        @include at-breakpoint(tablet) {
            @include heading-xl;
            margin-bottom: 16px;
        }
    }

    &-body {
        @include body-base-normal;

        p, a {
            @include body-base-normal;
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;
        margin-left: -80px;

        @include at-breakpoint(tablet) {
            display: block;
            margin-left: 0;
        }

        & + & {
            margin-top: 80px;

            @include at-breakpoint(tablet) {
                margin-top: 128px;
            }
        }

        &.-teasers {
            margin-left: -32px;
            margin-top: -274.5px;

            @include at-breakpoint(tablet) {
                margin-left: 0px;
                margin-top: -472px
            }

            @include at-breakpoint(phone) {
                margin-top: -408px
            }
        }

        &.-center {
            align-items: center;
            margin-left: 0;
            justify-content: center;
        }
    }

    &-teaser {
        margin-left: 32px;
        border: 26px solid $color-gray-fill-100;
        flex: 1;
        background: $color-white;
        min-height: 293px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 80px;
        min-width: calc(50% - 32px);
        max-width: calc(50% - 32px);

        @include at-breakpoint(tablet) {
            min-height: 328px;
            margin-left: 0;
            min-width: unset;
            max-width: none;
            padding: 38px 24px;

            &:not(:first-child) {
                margin-top: 32px;
            }
        }

        &Heading {
            margin-bottom: 24px;
        }

        &Text {
            @include body-base-normal;
            margin-bottom: 24px;
        }
    }

    &-content {
        flex: 1;
        margin-left: 80px;


        @include at-breakpoint(tablet) {
            margin-left: 0;

            &:not(:first-child) {
                margin-top: 80px;
            }
        }
    }

    &-logo {
        &Container {
            flex: 1;
            display: flex;
            justify-content: center;

            a {
                display: inline-block;
            }

            @include at-breakpoint(tablet) {
                margin-bottom: 80px;
            }
        }

        width: 189px;
        height: 180px;
        display: block;
        background-image: url('/images/Logo.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include at-breakpoint(phone) {
            width: 109px;
            height: 104px;
        }
    }

    &-links {
        @include reset-list;

        &Item {
            padding: 7px 0 8px;

            &:not(:first-child) {
                border-top: 1px solid $color-gray-fill-300;
            }
        }
    }

    &-link {
        @include reset-link;
        @include body-base-normal;
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
            flex: 1;
        }

        & > i {
            font-weight: normal;
            margin-left: 4px;
            margin-bottom: 2px;
            color: $color-gray-fill-400;
        }

        &:hover {
            span {
                text-decoration: underline;
            }

            & > i {
                color: $color-gray-fill-600;
            }
        }
    }

    &-socialPlusLogo {
        flex: 2 2;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @include at-breakpoint(tablet) {
            display: block;
        }
    }

    &-logos {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: right;

        @include at-breakpoint(tablet) {
            justify-content: center;
        }

        img {
            max-width: 120px;
            max-height: 90px;
        }

        & > a, & > img {
            &:not(:first-child) {
                margin-left: 40px;
            }
        }
    }

    &-socialMedia {
        display: flex;
        flex: 1 1;

        @include at-breakpoint(tablet) {
            justify-content: center;
            margin-bottom: 80px;
        }

        a {
            @include reset-link;

            & + a {
                margin-left: 32px;
            }
        }

        i {
            transition: all .5s ease;
            color: $color-gray-fill-400;
        }

        &Instagram {
            &:hover {
                color: #e56969;
            }
        }

        &Facebook {
            &:hover {
                color: #4267B2;
            }
        }

        &LinkedIn {
            &:hover {
                color: #126BC4;
            }
        }
    }
}
