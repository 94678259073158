﻿/// <reference path="../__references.scss" />

$component-name: 'Sponsor';
$component-base: '.#{$component-name}';

//Use only in this file
#{$component-base} {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1px;

    @include at-breakpoint(phone) {
        margin-left: 0;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;
        width: 50%;
        text-align: center;
        padding: 2rem;
        border-bottom: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        border-left: 1px solid transparent;
        flex: 1;
        margin-left: 1px;
        min-width: calc((100% / 4) - 1px);
        max-width: calc((100% / 4) - 1px);

        @include at-breakpoint(desk-up) {
            &:nth-child(4n) {
                border-right: none;
            }

            &:nth-last-child(-n+4) {
                border-bottom: none;
            }
        }

        @include at-breakpoint(tablet) {
            min-width: calc((100% / 2) - 1px);
            max-width: calc((100% / 2) - 1px);

            &:nth-child(2n) {
                border-right: none;
            }

            &:nth-last-child(-n+2) {
                border-bottom: none;
            }
        }
    }

    &-image {
        max-width: 180px;
        max-height: 100px;

        @include at-breakpoint(phone) {
            max-width: 100%;
        }
    }
}


