﻿/// <reference path="../__references.scss" />

$component-name: 'Link';
$component-base: '.#{$component-name}';

#{$component-base} {
    @include body-small-loose;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    position: relative;

    & > span {
        font-weight: bold;

        & + i {
            margin-left: 12px;
        }
    }

    & > i + span {
        margin-left: 12px;
    }

    &:hover {
        span {
            text-decoration: underline;
        }

        & > i {
            text-decoration: none !important;
        }
    }

    &.-primary {
        color: $color-primary-fill-800;
    }
}
